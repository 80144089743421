import Vue from 'vue';
import Vuex from 'vuex';
import { Utils } from '@/utils.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    courses: [],
    topics: [],
    units: []
  },

  actions: {
    getAllCourses({ commit }, status) {
      window.$.ajax({
        url: Utils.getUrl('get_all_courses.php'),
        data: { status: status },
        success: (response) => {
          commit('SET_COURSES', JSON.parse(response));
        },
        error: function (resp) {
          console.log(resp);
        }
      });
    },
    changeUnitParent({ commit }, payload) {
      if (!payload || !payload.unit || !payload.subTopic) {
        window.$("#toast-bad").toast("show");
        return;
      }
      window.$.ajax({
        url: Utils.getUrl('get_course_units_by_topic_and_subtopic_id.php'),
        data: { topicId: payload.subTopic.topicId, subtopicId: payload.subTopic.subtopicId },
        method: 'get',
        success: (response) => {
          console.log(commit, response);

          let units = JSON.parse(response);
          let lastUnitCount = 1;
          if (units.length) {
            units.sort((a, b) => {
              const aId = parseInt(a.unitId.split("-")[1]);
              const bId = parseInt(b.unitId.split("-")[1]);
              if (aId < bId) return -1;
              if (aId > bId) return 1;
              return 0;
            });
            let lastUnit = units[units.length - 1];
            lastUnitCount = lastUnit.unitId.split("-")[1];
            lastUnitCount = parseInt(lastUnitCount) + 1;
          }

          let unit = JSON.parse(JSON.stringify(payload.unit));
          let newParentSubTopic = payload.subTopic;

          unit.unitId = newParentSubTopic.id + "-" + lastUnitCount;
          unit.subtopicId = newParentSubTopic.subtopicId;
          unit.topicId = newParentSubTopic.topicId;

          this.dispatch("saveUnit", unit);
          this.dispatch("deleteUnit", JSON.parse(JSON.stringify(payload.unit)));
        },
        error: (resp) => {
          console.log(resp);
        }
      });
    },

    getCoursesByStatus({ commit }, status) {
      window.$.ajax({
        url: Utils.getUrl('get_courses_by_status.php'),
        data: { status: status },
        success: (response) => {
          commit('SET_COURSES', JSON.parse(response));
        },
        error: function (resp) {
          console.log(resp);
        }
      });
    },

    getTopics({ commit }, courseId) {
      window.$.ajax({
        url: Utils.getUrl('/get_course_topics_by_id.php'),
        data: { "courseId": courseId },
        method: 'get',
        success: (response) => {
          commit('SET_TOPICS', JSON.parse(response));
        },
        error: (resp) => {
          console.log(resp);
        }
      });
    },

    getUnits({ commit }, subtopic) {
      window.$.ajax({
        url: Utils.getUrl('get_course_units_by_topic_and_subtopic_id.php'),
        data: { topicId: subtopic.topicId, subtopicId: subtopic.subtopicId },
        method: 'get',
        success: (response) => {
          commit('SET_UNITS', JSON.parse(response));
        },
        error: (resp) => {
          console.log(resp);
        }
      });
    },

    deleteUnit({ commit }, unit) {
      window.$.ajax({
        url: Utils.getAdminUrl("delete_unit.php"),
        data: JSON.stringify({ unitId: unit.unitId }),
        method: "POST",
        dataType: "json",
        crossDomain: true,
        contentType: "application/json",
        success: function (res) {
          commit("DELETE_UNIT", unit.unitId);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
        },
        error: function (res) {
          console.log(res.responseText);
          window.$("#toast-bad").toast("show");
        },
      });
    },

    addUnit({ commit }, unit) {
      commit('ADD_UNIT', unit);
    },

    saveUnit({ commit }, unit) {
      window.$.ajax({
        url: Utils.getAdminUrl("save_unit.php"),
        data: JSON.stringify(unit),
        method: "POST",
        dataType: "json",
        crossDomain: true,
        contentType: "application/json",
        success: (res) => {
          commit('ADD_UNIT', unit);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
        },
        error: (res) => {
          console.log(res);
          window.$("#toast-bad").toast("show");
        },
      });
    },

    deleteTopic({ commit }, topic) {
      window.$.ajax({
        url: Utils.getAdminUrl("delete_topic.php"),
        data: JSON.stringify({ topicId: topic.topicId }),
        method: "POST",
        dataType: "json",
        crossDomain: true,
        contentType: "application/json",
        success: (res) => {
          commit('DELETE_TOPIC', topic);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
        },
        error: (res) => {
          console.log(res.responseText);
          window.$("#toast-bad").toast("show");
        },
      });
    },

    saveTopic({ commit }, topic) {
      window.$.ajax({
        url: Utils.getAdminUrl("save_topic.php"),
        data: JSON.stringify(topic),
        method: "POST",
        dataType: "json",
        contentType: "application/json",
        success: (res) => {
          console.log(res);
          console.log(commit);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
          this.dispatch("getTopics", topic.courseId);
        },
        error: (res) => {
          console.log(res);
          window.$("#toast-bad").toast("show");
        },
      });
    },

    duplicateSubtopic({ commit }, jsonSubTopic) {
      window.$.ajax({
        url: Utils.getAdminUrl("save_subtopic.php"),
        data: JSON.stringify(jsonSubTopic),
        method: "POST",
        dataType: "json",
        contentType: "application/json",
        success: (res) => {
          console.log(commit);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
          commit("APPEND_SUBTOPIC", jsonSubTopic);
        },
        error: () => {
          window.$("#toast-bad").toast("show");
        },
      });
    },

    deleteSubtopic({ commit }, subtopic) {
      // const selectedTopic = this.topic;
      window.$.ajax({
        url: Utils.getAdminUrl("delete_subtopic.php"),
        data: JSON.stringify({ id: subtopic.id }),
        method: "POST",
        dataType: "json",
        crossDomain: true,
        contentType: "application/json",
        success: (res) => {
          commit("DELETE_SUBTOPIC", subtopic);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
        },
        error: (res) => {
          console.log(res.responseText);
          window.$("#toast-bad").toast("show");
        },
      });
    },

    saveSubtopic({ commit }, jsonSubTopic) {
      window.$.ajax({
        url: Utils.getAdminUrl("save_subtopic.php"),
        data: JSON.stringify(jsonSubTopic),
        method: "POST",
        dataType: "json",
        contentType: "application/json",
        success: (res) => {
          console.log(commit);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
        },
        error: () => {
          window.$("#toast-bad").toast("show");
        },
      });
    }
  },

  mutations: {
    DELETE_UNIT(state, unitId) {
      state.units = state.units.filter((u) => {
        return u.unitId !== unitId;
      });
    },
    ADD_UNIT(state, unit) {
      state.units.push(unit);
    },
    SET_COURSES(state, courses) {
      state.courses = [];
      courses.forEach(c => {
        state.courses.push(c);
      });
    },

    SET_TOPICS(state, topics) {
      state.topics = topics;
    },

    DELETE_TOPIC(state, topic) {
      state.topics = state.topics.filter(t => {
        return t.topicId != topic.topicId;
      });
    },
    SET_UNITS(state, units) {
      state.units = units;
    },

    DELETE_SUBTOPIC(state, subTopic) {
      for (let i = 0; i < state.topics.length; i++) {
        let t = state.topics[i];
        t.subTopics = t.subTopics.filter((st) => {
          return subTopic.id != st.id;
        });
      }
    },

    APPEND_SUBTOPIC(state, subTopic) {
      for (let i = 0; i < state.topics.length; i++) {
        let t = state.topics[i];
        if (t.topicId == subTopic.topicId) {
          t.subTopics.push(subTopic);
        }
      }
    }
  }
});
