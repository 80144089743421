<template>
  <div id="app">
    <NavBar v-show="!isRouteName('/course-editor')" />
    <router-view />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
export default {
  components: {
    NavBar,
  },
  methods: {
    isRouteName(route) {
      console.log(this.$route.path);
      return this.$route.path == route;
    },
  },
};
</script>

<style>
*,
body,
html {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0d2136;
  /* margin: 0 8px; */
  font-size: 14px;
}

.heading {
  background: #0d2136;
  color: white;
  padding: 4px;
}
.drop-target {
  background: rgb(44, 255, 72) !important;
  padding: 8px 0;
}
.selected {
  background: #0084ff !important;
  /* color: white !important; */
  cursor: pointer;
  border-radius: 2px;
}

.panel {
  border: 1px solid rgb(24 49 89);
  background: #0d2136;
  padding: 2px;
  border-radius: 4px;
  /* margin: 4px; */
  margin-bottom: 4px;
  color: white;
}
.panel-header {
  border-radius: 2px;
  padding: 4px;
  background: #162c46;
}
input[type="text"] {
  border: 1px solid #090f23;
  margin: 2px 0 2px 0;
  padding: 0 0 0 4px;
  border-radius: 2px;
  background: #090f23;
  color: white;
  width: 100%;
}

textarea:focus,
select,
input:focus {
  outline: none;
}
</style>
