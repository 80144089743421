<template>
  <div class="toast-container">
    <div
      id="toast-good"
      class="toast align-items-center bg-success text-white"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-bs-delay="1000"
    >
      <div class="d-flex">
        <div class="toast-body">Success</div>
      </div>
    </div>
    <div
      id="toast-bad"
      class="toast align-items-center bg-danger text-white"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-bs-delay="1000"
    >
      <div class="d-flex">
        <div class="toast-body">Failure</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Toast',
  props: {
    message: String,
    code: String
  },
  data() {
    return {
    };
  },

}
</script>

<style scoped>
.toast-container {
  position: fixed;
  top: 0;
  right: 0;
}
</style>