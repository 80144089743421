<template>
  <div>
    <div class="panel">
      <div class="label">Course</div>

      <select
        class="course-sel"
        @change="populateCourseEditor($event.target.value)"
      >
        <option
          v-for="course in getCourses"
          :key="course.courseId"
          :value="JSON.stringify(course)"
        >
          {{ course.title }}
        </option>
      </select> &nbsp;
      <button type="button" class="btn btn-sm btn-primary" @click="isCourseDetailsVisible = !isCourseDetailsVisible">Collapse</button>

      <div class="row" v-show="isCourseDetailsVisible">
        <div class="col-md">
          <div>
            <div class="label">CourseID:</div>
            <input class="course-input" type="text" v-model="course.courseId" />
          </div>
          <div>
            <div class="label">Topics Count:</div>
            <input
              class="course-input"
              type="text"
              v-model="course.topicCount"
            />
          </div>
          <div>
            <div class="label">Price:</div>
            <input class="course-input" type="text" v-model="course.price" />
          </div>
        </div>
        <div class="col-md">
          <div>
            <div class="label">Poster:</div>
            <input
              class="course-input"
              type="text"
              v-model="course.imagePath"
            />
          </div>
          <div>
            <div class="label">Title:</div>
            <input class="course-input" type="text" v-model="course.title" />
          </div>
          <div>
            <div class="label">Rating:</div>
            <input class="course-input" type="text" v-model="course.rating" />
          </div>
        </div>
        <div class="col-md">
          <div>
            <div class="label">Status:</div>
            <select v-model="course.status">
              <option value="available_android">Publish Android</option>
              <option value="available_android_ios">Publish Android iOS</option>
              <option value="available_ios">Publish iOS</option>
              <option value="upcoming">Upcoming</option>
              <option value="disabled">Disabled</option>
            </select>
          </div>
          <div>
            <div class="label">Description:</div>
            <input
              class="course-input"
              type="text"
              v-model="course.description"
            />
          </div>
          <div>
            <div class="label"></div>
            <button @click="openEditor" class="btn btn-primary btn-sm">
              {{ htmlEditor.isEditorHidden ? "Open Editor" : "Close Editor" }}
            </button>
            &nbsp;
            <button class="btn btn-primary btn-sm" @click="saveCourse()">
              Save
            </button>
          </div>

          <div class="gap-1 d-flex mt-1 mb-1">
            <div class="label"></div>
            <div id="editor-dialog">
              <!-- Create the editor container -->
              <div id="editor-container">
                <div id="editor">
                  <div id="editor-body"></div>
                </div>
              </div>
              <div class="editor-bottom-bar">
                <button @click="openEditor" class="btn btn-primary btn-sm">
                  {{
                    htmlEditor.isEditorHidden ? "Open Editor" : "Close Editor"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Utils } from "@/utils.js";

export default {
  name: "Courses",
  props: {
    msg: String,
  },
  data() {
    return {
      courses: [],
      isCourseDetailsVisible: false,
      htmlEditor: {
        quill: null,
        isEditorHidden: true,
      },
      course: {},
      selectedCourseId: "",
    };
  },

  mounted() {
    this.$store.dispatch("getAllCourses");
  },

  computed: {
    getCourses() {
      return this.$store.state.courses;
    },
  },

  methods: {
    openEditor() {
      if (this.htmlEditor.isEditorHidden) {
        document.querySelector("#editor-body").innerHTML =
          this.course.description;
        // document.querySelector("#editor-container").style.position = "absolute";
        document.querySelector("#editor-dialog").style.display = "block";
        setTimeout(() => {
          this.htmlEditor.quill = new window.Quill("#editor-body", {
            theme: "snow",
          });
        }, 500);
      } else {
        this.course.description = this.htmlEditor.quill.root.innerHTML;
        document.querySelector("#editor-container").innerHTML =
          '<div id="editor"><div id="editor-body"></div></div>';
        document.querySelector("#editor-dialog").style.display = "none";
      }

      this.htmlEditor.isEditorHidden = !this.htmlEditor.isEditorHidden;
    },
    populateCourseEditor(course) {
      course = JSON.parse(course);
      this.selectedCourseId = course.courseId;
      this.course = course;
      this.$store.dispatch("getTopics", course.courseId);
    },

    unescapeHTML(escapedHTML) {
      if (!escapedHTML) return "";
      return escapedHTML
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
    },

    saveCourse() {
      window.$.ajax({
        url: Utils.getAdminUrl("save_course.php"),
        data: JSON.stringify(this.course),
        method: "POST",
        dataType: "json",
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
        },
        error: function (res) {
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
          console.log(res);
        },
      });
    },
  },
};
</script>

<style scoped>
#editor-container {
  color: black;
}
#editor-dialog {
  display: none;
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 460px;
  box-shadow: 0 0 20px 0px #00000082;
}
.editor-bottom-bar {
  background: #2b405f;
  border-left: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  border-right: 1px solid #c3c3c3;
  padding: 4px;
}
/* #editor {
  position: absolute;
  left: 0;
  width: 600px;
  height: 800px;
  background-color: #fff;
  border: 4px solid blue;
  box-shadow: 0px 0px 20px #0077ff;
} */
.course-sel {
  border: 1px solid #d8d8d8;
  width: 300px;
}
.course-input {
  width: 400px;
}

.label {
  display: inline-block;
  width: 100px;
}

.boundary {
  border: 1px solid #e0e0e0;
}
</style>
