export class Utils {
    static getUrl(url) {
        const host = location.hostname;
        const protocol = location.protocol;
        const subDomain = host.startsWith('localhost') ? '/flitm-server' : '';
        const finalUrl = protocol + '//' + host + subDomain + '/api/' + url;
        return finalUrl;
    }

    static getAdminUrl(url) {
        const host = location.hostname;
        const protocol = location.protocol;
        const subDomain = host.startsWith('localhost') ? '/flitm-server' : '';

        const finalUrl = protocol + '//' + host + subDomain + '/admin/' + url;
        console.log(finalUrl);
        return finalUrl;
    }
}