<template>
  <div>
    <div
      id="toast"
      style="width: 100%; height: 20px; position: fixed; top: 0"
    ></div>
    <div class="panel">
      <div class="col">
        <div class="panel-header">Unit Editor</div>
      </div>
      <div class="row">
        <div class="col">
          <table>
            <tbody>
              <tr>
                <td>
                  Unit Id:
                  <input
                    id="unitId"
                    type="text"
                    v-model="unit.unitId"
                    placeholder="unitId"
                  />
                </td>
                <td>
                  Topic Id:
                  <input
                    id="topicId"
                    type="text"
                    v-model="unit.topicId"
                    placeholder="topicId"
                  />
                </td>
                <td>
                  Subtopic Id
                  <input
                    id="subtopicId"
                    type="text"
                    v-model="unit.subtopicId"
                    placeholder="subtopicId"
                  />
                </td>

                <td>
                  Unit Type
                  <select v-model="unit.type">
                    <option value="TEXT">TEXT</option>
                    <option value="QUIZ">QUIZ</option>
                    <option value="CODE">CODE</option>
                    <option value="CARD">CARD</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="gap-2 d-flex mt-1 mb-1">
            <button class="btn btn-primary btn-sm" @click="saveUnit">
              Save
            </button>

            <button
              class="btn btn-primary btn-sm"
              @click.stop.prevent="deleteUnit(unit)"
            >
              Delete
            </button>

            <!-- <a target="_blank" href="/code-editor.html"
              ><button class="btn btn-primary btn-sm">OpenEditor</button></a
            > -->
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-4">
              <div class="menu">
                <div class="ui checkbox gap-2 d-flex">
                  <button
                    type="button"
                    name="example"
                    class="ui btn btn-primary btn-sm"
                    @click="editorTextToggle = !editorTextToggle"
                  >
                    T/D
                  </button>
                  <button
                    type="button"
                    name="example"
                    class="ui btn btn-primary btn-sm"
                    @click="onAdd('text')"
                  >
                    +Text
                  </button>
                  <button
                    type="button"
                    name="example"
                    class="ui btn btn-primary btn-sm"
                    @click="onAdd('image')"
                  >
                    +Image
                  </button>
                  <button
                    type="button"
                    name="example"
                    class="ui btn btn-primary btn-sm"
                    @click="onAdd('code')"
                  >
                    +Code
                  </button>
                </div>
              </div>

              <textarea
                v-show="editorTextToggle"
                v-model="unit.data"
              ></textarea>

              <div
                v-show="!editorTextToggle"
                v-for="(item, itemIndex) in getData(unit)"
                :key="itemIndex"
              >
                <div>
                  <div class="linkText">
                    <select
                      :id="'type-' + itemIndex"
                      v-model="item.type"
                      class="section-type"
                      @change="onTypeChange($event)"
                    >
                      <option value="text">Text</option>
                      <option value="code">Code</option>
                      <option value="image">Image</option>
                      <option value="heading">Heading</option>
                      <option value="isDone">IsDone</option>
                    </select>
                  </div>
                  <!-- {{ item.type.toUpperCase() }}</div> -->
                  <!-- QUIZ START -->
                  <div v-if="item.type == 'answers'">
                    <input
                      v-for="(option, oIndex) in item.data"
                      :key="oIndex"
                      :data-type="item.type"
                      class="unitDataData"
                      type="text"
                      :value="option"
                      @keyup="onUnitDataChange($event)"
                    />
                  </div>
                  <div v-else-if="item.type == 'options'">
                    <input
                      v-for="(option, oIndex) in item.data"
                      :key="oIndex"
                      :data-type="item.type"
                      class="unitDataData"
                      type="text"
                      :value="option"
                      @keyup="onUnitDataChange($event)"
                    />
                  </div>
                  <!-- QUIZ END -->
                  <div v-else>
                    <textarea
                      :id="'data' + itemIndex"
                      v-if="item.type == 'text' || item.type == 'code'"
                      :data-type="item.type"
                      class="unitDataData"
                      type="text"
                      :value="item.data"
                      @keyup="onUnitDataChange($event)"
                    ></textarea>
                    <input
                      v-else
                      :data-type="item.type"
                      class="unitDataData"
                      type="text"
                      :value="item.data"
                      @keyup="onUnitDataChange($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-8">
              <Preview v-if="unit.data" :unitData="unit.data" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Preview from "@/components/Preview.vue";
import { Utils } from "@/utils.js";
export default {
  name: "Unit",
  components: {
    Preview,
  },
  props: {
    unit: Object,
  },
  data() {
    return {
      editorTextToggle: false,
      editor: null,
      units: [],
      unitData: "{}",
      selectedUnitId: "",
      isValidJson: true,
    };
  },

  watch: {
    unit: {
      handler() {
        this.setEditorText();
      },
    },
  },

  mounted() {},

  methods: {
    onAdd(sectionType) {
      let unitData = JSON.parse(this.unit.data);
      unitData.push({
        type: sectionType,
        data: "",
      });

      this.unit.data = JSON.stringify(unitData, null, 2);
    },

    getData(unit) {
      return JSON.parse(unit.data || "{}");
    },

    setEditorText() {
      let jsonArray = JSON.parse(this.unit.data);
      this.unit.data = JSON.stringify(jsonArray);
    },

    onTypeChange(event) {
      console.log(event.target.id);
      const index = parseInt(event.target.id.split("-")[1]);
      let unitData = JSON.parse(this.unit.data);
      unitData[index].type = event.target.value;
      this.unit.data = JSON.stringify(unitData);
    },

    onUnitDataChange() {
      let unitData = [];
      if (this.unit.type === "QUIZ") {
        unitData = this.onQuizDataChange();
      } else {
        try {
          let unitDataData = document.querySelectorAll(".unitDataData");
          for (let i = 0; i < unitDataData.length; i++) {
            let section = {};
            section.type = unitDataData[i].getAttribute("data-type");
            section.data = unitDataData[i].value;
            if (section.data !== "xxx") {
              unitData.push(section);
            }
          }
        } catch (e) {
          this.isValidJson = false;
        }
      }

      this.unit.data = JSON.stringify(unitData, null, 2);
      this.isValidJson = true;
      console.log(JSON.stringify(unitData));
    },

    onQuizDataChange() {
      let unitData = [];
      let options = [];
      let answers = [];
      let unitDataData = document.querySelectorAll(".unitDataData");
      for (let i = 0; i < unitDataData.length; i++) {
        const type = unitDataData[i].getAttribute("data-type");
        if (type === "answers") {
          answers.push(parseInt(unitDataData[i].value));
        } else if (type == "options") {
          options.push(unitDataData[i].value);
        } else {
          let section = {};
          section.type = type;
          section.data = unitDataData[i].value;
          unitData.push(section);
        }
      }

      if (answers.length > 0) {
        unitData.push({ type: "answers", data: answers });
      }
      if (options.length > 0) {
        unitData.push({ type: "options", data: options });
      }

      return unitData;
    },

    populateUnitEditor(unit) {
      this.selectedUnitId = unit.unitId;
      this.unit = unit;
    },

    saveUnit() {
      window.$.ajax({
        url: Utils.getAdminUrl("save_unit.php"),
        data: JSON.stringify(this.unit),
        method: "POST",
        dataType: "json",
        crossDomain: true,
        contentType: "application/json",
        success: function (res) {
          console.log(res.code);
          window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
        },
        error: function (res) {
          console.log(res.code);
          window.$("#toast-bad").toast("show");
        },
      });
    },

    deleteUnit(unit) {
      this.$store.dispatch("deleteUnit", unit);
    },
  },
};
</script>

<style>
.menu {
  margin: 8px 0;
}
.menu-btn {
  border: 1px solid #2c3c55;
  padding: 2px 4px;
  background: #3b71ca;
  margin: 0 2px;
  border-radius: 5px;
}
.linkText {
  color: #0084ff;
  background: #162c46;
}
.invalid {
  border: 4px dashed rgb(223, 0, 0);
}
select {
  width: 140px;
  padding: 4px;
  border: 1px solid #9b9b9b;
  background: white;
}

.CodeMirror {
  /* width: 800px !important; */
  font-size: 12px;
  height: 500px !important;
  border: 1px solid black;
}
.section-type {
  background: #516077;
  color: white;
  height: 26px;
  font-size: 12px;
  border: none;
}

table {
  width: 100%;
}

textarea {
  border: 1px solid #090f23;
  width: 100%;
  height: 100%;
  background: #090f23;
  color: white;
}
</style>
