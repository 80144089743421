<template>
  <div class="unit-card" @click="speakSelectedText">
    <div class="panel">
      <div class="text-cont">
        <div v-for="(item, itemIndex) in JSON.parse(unitData)" :key="itemIndex">
          <!-- quiz -->
          <div
            class="h5 mt-3 mb-4 question"
            align="center"
            v-show="item.type === 'question'"
          >
            {{ item.data }}
          </div>
          <div class="" align="left" v-show="item.type === 'options'">
            {{ item.data[0] }}
            <div v-for="(option, index) in item.data" :key="index">
              <!-- {{ item.data[index] }} -->
              <div class="text">
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />{{ item.data[index] }}
              </div>
            </div>
          </div>

          <div
            class="h4 heading"
            align="center"
            v-show="item.type === 'heading'"
          >
            {{ item.data }}
          </div>
          <div class="h4" v-if="item.type === 'image'">
            <img :src="item.data" class="img-fluid" />
          </div>
          <div v-if="item.type === 'code'">
            <div class="code">{{ item.data }}</div>
            <div class="try">Try in editor...</div>
          </div>
          <div v-if="item.type === 'text'">
            <div class="text">{{ item.data }}</div>
          </div>

          <!-- <div v-if="item.type === 'sentences'">
            <div class="text" v-for="(text, index) in item.data" :key="index">
              {{ text }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="next-button" align="center">Next</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Preview",
  props: {
    unitData: {},
  },
  data() {
    return {
      sentences: [],
    };
  },
  watch: {
    unitData: {
      handler() {
        this.sentences = [];
      },
    },
  },
  methods: {
    speakSelectedText(event) {
      if (event.target.className == "text") {
        this.speak(event.target.innerText);
      }

      console.log(event);
    },
    speak(text) {
      let msg = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(msg);
    },
  },
};
</script>

<style scoped>
.question {
  color: black;
}
.unit-card {
  min-width: 400px;
  max-width: 400px;
}

.heading {
  color: black;
  background: white;
}
.text {
  padding: 6px;
  margin: 6px;
  font-size: 12px;
  background: #f4f4f4;
  color: black;
}

.next-button {
  background: linear-gradient(40deg, #005897, #0486f5);
  padding: 8px;
  color: white;
  cursor: pointer;
  margin: 2px;
}

.code {
  background: #2b2b2b;
  padding: 8px;
  margin: 4px 0 0px 0;
  color: white;
  white-space: pre-wrap;
  font-size: 12px;
}

.text-cont {
  height: 600px;
  overflow-y: auto;
  background-color: white;
}

.try {
  padding: 8px 0 8px 8px;
  background: rgb(241, 241, 241);
  color: rgb(0, 162, 255);
}
</style>