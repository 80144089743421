<template>
  <div>
    <div
      id="toast"
      style="width: 100%; position: fixed; top: 0"
    ></div>

    <div class="row bg">
      <div class="col-3 bg">
        <div class="panel">
          <div class="panel-header">Topics</div>
          <!-- topics -->
          <div
            v-for="(topic, index) in getTopics"
            :key="topic.topicId"
            class="topic"
          >
            <a
              class="link"
              data-mdb-toggle="collapse"
              :href="'#' + topic.topicId"
              role="button"
              @click="populateTopicEditor(topic)"
            >
              T{{ index + 1 }}. {{ topic.title }} ({{ topic.subTopics.length }})
            </a>
            <!-- subtopics -->
            <div
              class="subtopic collapse link"
              :id="subTopic.topicId"
              :key="subTopic.subtopicId"
              @drop.prevent="dropFile"
              @dragover.prevent="dragOver"
              @dragleave.prevent="dragLeave"
              @click="populateSubTopicEditor(subTopic)"
              v-for="(subTopic, index) in topic.subTopics"
            >
              <div
                :data-subtopic="JSON.stringify(subTopic)"
                :class="[
                  'subTopicDropTarget',
                  {
                    selected: selectedSubTopic.id == subTopic.id,
                  },
                ]"
              >
                S{{ index + 1 }}. {{ subTopic.title }}
              </div>
              <!-- units -->
              <template v-for="unit in getUnits">
                <div
                  draggable="true"
                  :key="unit.unitId"
                  :data-unit="JSON.stringify(unit)"
                  v-if="subTopic.id == unit.unitId.split('-')[0]"
                  @click="populateUnitEditor(unit)"
                  @dragstart="dragStart"
                  :title="unit.unitId"
                  :class="['unit', { selected: selectedUnitId == unit.unitId }]"
                >
                  {{ JSON.parse(unit.data)[0].data }}
                  <!-- - {{ JSON.parse(unit.data)[2].data[0] }} -->
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column gap-1 mt-5">
          <button class="btn btn-primary btn-sm" @click="onDuplicateUnit">
            Dup Unit
          </button>
          <button class="btn btn-primary btn-sm" @click="onDuplicateSubTopic">
            Dup Subtopic
          </button>
          <button class="btn btn-primary btn-sm" @click="onDuplicateTopic">
            Dup Topic
          </button>

          <!-- <button class="btn btn-primary btn-sm" @click="onAddFromList">
            Add from List
          </button> -->
        </div>
        <div></div>
      </div>

      <div class="col-2 bg">
        <div class="row">
          <div class="col">
            <div class="panel">
              <div class="panel-header">Topic Editor</div>
              <!-- topic editor -->
              <div ref="topicEditor" class="details">
                <div>
                  <div class="label">Course Id</div>
                  <input
                    class="input-text"
                    type="text"
                    v-model="topic.courseId"
                  />
                </div>
                <div>
                  <div class="label">Topic Id</div>
                  <input
                    class="input-text"
                    type="text"
                    v-model="topic.topicId"
                  />
                </div>
                <div>
                  <div class="label">Title</div>
                  <input class="input-text" type="text" v-model="topic.title" />
                </div>
                <div>
                  <div class="label">Points</div>
                  <input
                    class="input-text"
                    type="text"
                    v-model="topic.points"
                  />
                </div>
                <div class="mt-1 gap-2 d-flex">
                  <button class="btn btn-primary btn-sm" @click="saveTopic()">
                    Save
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="deleteTopic(topic)"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="panel">
              <div class="panel-header">Subtopic Editor</div>
              <!-- sub topic editor -->
              <div ref="subtopicEditor" class="details">
                <div>
                  <div class="label">Id</div>
                  <input
                    class="input-text"
                    id="sId"
                    type="text"
                    v-model="selectedSubTopic.id"
                  />
                </div>
                <div>
                  <div class="label">Topic Id</div>
                  <input
                    class="input-text"
                    id="topicId"
                    type="text"
                    v-model="selectedSubTopic.topicId"
                  />
                </div>
                <div>
                  <div class="label">Subtopic Id</div>
                  <input
                    class="input-text"
                    id="subtopicId"
                    type="text"
                    v-model="selectedSubTopic.subtopicId"
                  />
                </div>
                <div>
                  <div class="label">Title</div>
                  <input
                    id="title"
                    class="input-text"
                    type="text"
                    v-model="selectedSubTopic.title"
                  />
                </div>
                <div>
                  <div class="label">Points</div>
                  <input
                    id="points"
                    class="input-text"
                    type="text"
                    v-model="selectedSubTopic.points"
                  />
                </div>
                <div class="mt-1 gap-2 d-flex">
                  <button class="btn btn-primary btn-sm" @click="saveSubTopic">
                    Save
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="deleteSubtopic(selectedSubTopic)"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-7">
        <!-- unit editor -->
        <div ref="unitEditor" class="details">
          <UnitEditor v-if="this.unit" :unit="unit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnitEditor from "@/components/UnitEditor.vue";
export default {
  name: "Topics",
  components: {
    UnitEditor,
  },
  props: {
    courseId: String,
  },
  data() {
    return {
      selectedUnitId: "",
      topic: {},
      selectedSubTopic: {},
      unit: {},
      selectedSubtopicId: "",
      wordIndex: 5,
    };
  },

  computed: {
    getUnits() {
      const units = this.$store.state.units;
      units.sort((a, b) => {
        const aId = parseInt(a.unitId.split("-")[1]);
        const bId = parseInt(b.unitId.split("-")[1]);
        if (aId < bId) return -1;
        if (aId > bId) return 1;
        return 0;
      });

      return units;
    },

    getTopics() {
      const topics = this.$store.state.topics;
      topics.sort((a, b) => {
        const aId = parseInt(a.topicId.split("-")[1].replace("t", ""));
        const bId = parseInt(b.topicId.split("-")[1].replace("t", ""));
        if (aId < bId) return -1;
        if (aId > bId) return 1;
        return 0;
      });

      return topics;
    },
  },

  methods: {
    dragStart(event) {
      if (event.target && event.target.getAttribute("data-unit")) {
        event.dataTransfer.setData(
          "UnitData",
          event.target.getAttribute("data-unit")
        );
      }
    },

    dragOver(event) {
      const target = event.target;
      if (target.classList.contains("subTopicDropTarget") > 0) {
        target.classList.add("drop-target");
      }
    },

    dragLeave(event) {
      event.target.classList.remove("drop-target");
    },

    dropFile(event) {
      this.dragLeave(event);

      if (event && event.dataTransfer) {
        let unit = event.dataTransfer.getData("UnitData");
        let subTopic = event.target.getAttribute("data-subtopic");

        unit = JSON.parse(unit);
        subTopic = JSON.parse(subTopic);
        if (!unit || !subTopic) return;

        if (
          unit.topicId !== subTopic.topicId ||
          unit.subtopicId !== subTopic.subtopicId
        ) {
          this.$store.dispatch("changeUnitParent", {
            unit: unit,
            subTopic: subTopic,
          });
        }
      }
    },

    populateTopicEditor(topic) {
      this.topic = topic;
    },

    populateSubTopicEditor(subtopic) {
      this.selectedSubTopic = subtopic;

      this.$store.dispatch("getUnits", subtopic);
    },

    populateUnitEditor(unit) {
      this.selectedUnitId = unit.unitId;
      this.unit = unit;

      // const data = JSON.parse(unit.data);
      // data.forEach((d) => {
      //   if (d.type === "image") {
      //     const index = d.data.lastIndexOf("/");
      //     const extIndex = d.data.indexOf(".jpg");
      //     const imageName = d.data.substr(index + 1, extIndex - (index + 1));
      //     window.open(
      //       "https://www.pexels.com/search/" +
      //         imageName +
      //         "/?orientation=landscape", "_blank"
      //     );
      //   }
      // });
    },

    onDuplicateTopic() {
      let dupTopic = JSON.parse(JSON.stringify(this.topic));
      const topicIdParts = dupTopic.topicId.split("-");
      if (topicIdParts.length < 2) {
        window.$("#toast-bad").toast("show");
        return;
      }

      const courseId = topicIdParts[0];
      const topicId = topicIdParts[1];
      let currentTopicNumber = parseInt(topicId.match(/\d/g).join(""));
      currentTopicNumber++;
      dupTopic.topicId = courseId + "-t" + currentTopicNumber;
      delete dupTopic.subTopics;

      console.log(JSON.stringify(dupTopic));
      const topics = this.getTopics;
      let isExists = false;
      topics.forEach((topic) => {
        if (topic.topicId === dupTopic.topicId) isExists = true;
      });

      if (!isExists) {
        // window.$("#toast-good").toast("show");
        this.$store.dispatch("saveTopic", dupTopic);
      } else {
        window.$("#toast-bad").toast("show");
      }
    },

    onDuplicateSubTopic() {
      let jsonSubTopic = JSON.parse(JSON.stringify(this.selectedSubTopic));
      const topicIdParts = jsonSubTopic.topicId.split("-");
      const subtopicIdParts = jsonSubTopic.subtopicId.split("-");
      if (topicIdParts.length < 2 || subtopicIdParts.length < 2) {
        window.$("#toast-bad").toast("show");
        return;
      }

      const courseId = topicIdParts[0];
      const topicId = topicIdParts[1];
      const currentSubtopicId = parseInt(
        subtopicIdParts[1].match(/\d/g).join("")
      );

      const newSubtopicId = "s" + (currentSubtopicId + 1);
      jsonSubTopic.id = courseId + topicId + newSubtopicId;
      jsonSubTopic.subtopicId = courseId + "-" + newSubtopicId;

      this.$store.dispatch("duplicateSubtopic", jsonSubTopic);
    },

    // onAddFromList() {
    //   for (let day in Days) {
    //     const list = Days[day];
    //     const dayCount = day.split("-")[1];

    //     const topicId = "t" + dayCount;
    //     const unit = {
    //       data: "",
    //       subtopicId: "kfc-s1",
    //       topicId: "kfc-" + topicId,
    //       type: "CARD",
    //       unitId: "kfc" + topicId + "s1-",
    //     };

    //     for (let i = 0; i < list.length; i++) {
    //       let word = list[i];
    //       const unitClone = JSON.parse(JSON.stringify(unit));
    //       unitClone.data = JSON.stringify(word);
    //       unitClone.unitId = unit.unitId + (i + 1);
    //       this.$store.dispatch("saveUnit", unitClone);
    //     }
    //   }
    // },

    onDuplicateUnit() {
      let unit = JSON.parse(JSON.stringify(this.unit));

      let unitIdParts = unit.unitId.split("-");
      if (unitIdParts.length < 2) {
        window.$("#toast-bad").toast("show");
        return;
      }

      unit.unitId = unitIdParts[0] + "-" + (parseInt(unitIdParts[1]) + 1);
      this.$store.dispatch("saveUnit", unit);
    },

    saveUnit(unit) {
      this.$store.dispatch("saveUnit", unit);
    },

    unescapeHTML(escapedHTML) {
      if (!escapedHTML) return "";
      return escapedHTML
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
    },

    saveTopic() {
      let topic = JSON.parse(JSON.stringify(this.topic));
      delete topic.subTopics;

      this.$store.dispatch("saveTopic", topic);
    },

    deleteTopic(topic) {
      this.$store.dispatch("deleteTopic", topic);
    },

    saveSubTopic() {
      this.$store.dispatch("saveSubtopic", this.selectedSubTopic);
    },

    deleteSubtopic(subtopic) {
      this.$store.dispatch("deleteSubtopic", subtopic);

      //   const selectedTopic = this.topic;
      //   window.$.ajax({
      //     url: Utils.getAdminUrl("delete_subtopic.php"),
      //     data: JSON.stringify({ id: subtopic.id }),
      //     method: "POST",
      //     dataType: "json",
      //     crossDomain: true,
      //     contentType: "application/json",
      //     success: (res) => {
      //       selectedTopic.subTopics = selectedTopic.subTopics.filter((st) => {
      //         return subtopic.id != st.id;
      //       });
      //       window.$("#toast-" + (!res.code ? "good" : "bad")).toast("show");
      //     },
      //     error: (res) => {
      //       console.log(res.responseText);
      //       window.$("#toast-bad").toast("show");
      //     },
      //   });
    },
  },
};
</script>

<style scoped>
.bg {
  background-color: #0b121e;
  min-height: 100vh;
}
.unit-subheader {
  font-size: 8px;
  color: #162c46;
}
.link {
  color: white;
  cursor: pointer;
}

.unit {
  cursor: pointer;
  padding-left: 16px;
  /* font-size: 12px; */
  background-color: #19365b;
  margin: 4px;
}

.input-text {
  width: 100%;
}

.label {
  display: inline-block;
  width: 100px;
}
.topic {
  background: #1f324f;
  margin: 1px;
  padding: 4px;
  border-radius: 4px;
}

.subtopic {
  background: #17202b;
  margin: 0px 0 4px 16px;
  border-radius: 4px;
  padding: 2px;
  /* border-bottom: 1px solid #d8eaff; */
}

.subTopicDropTarget {
  padding: 2px;
}
</style>
