<template>
  <div class="home">
    <div>
      <Toast v-if="loggedIn" />
      <Courses v-if="loggedIn" />
      <Topics v-if="loggedIn" @onUnitSelected="onUnitSelected" />
    </div>
    <div :hidden="loggedIn">
      <h1>Login</h1>
      <input type="password" placeholder="password" v-model="password" /><br />
      <button class="btn btn-primary mt-5" @click="onPasswordCheck">
        Login
      </button>
    </div>
  </div>
</template>

<script>
import Toast from "@/components/Toast.vue";
import Courses from "@/components/Courses.vue";
import Topics from "@/components/Topics.vue";

export default {
  name: "Home",
  components: {
    Toast,
    Courses,
    Topics,
  },
  data() {
    return {
      password: "",
      loggedIn: true,
      courseId: "",
      topicId: "",
      subtopicId: "",
      unit: {},
    };
  },
  methods: {
    onUnitSelected(unit) {
      this.unit = unit;
      console.log(JSON.stringify(unit));
    },
    onPasswordCheck() {
      if (this.password === "!@#$%^") {
        this.loggedIn = true;
      }
      console.log(this.loggedIn, this.password);
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #0b121e;
}
</style>