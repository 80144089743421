<template lang="html">
    <div class="container">
        <div class="row">
            <div cols="12" class="content p-5">
                <div class="h1">Privacy Policy</div>
                <hr/>
                <div class="subheading">Application Privacy Statement</div>
                <div>This privacy statement (“Privacy Statement”) applies to the
                    treatment of personally identifiable information submitted by, or otherwise obtained
                    from, you in connection with the associated application (“Application”). The
                    Application
                    is provided by FLITM. By using or otherwise accessing the Application, you
                    acknowledge that you accept the practices and policies outlined in this Privacy
                    Statement.
                </div>
                <div class="subheading">WHAT PERSONAL INFORMATION DOES FLITM COLLECT?</div>
                <div>We collect the following types of information from our users:</div>
                <div class="subheading">Personal Information You Provide to Us:</div>
                <div>We may receive and store any information you submit to the
                    Application (or otherwise authorize us to obtain – such as, from (for example) your
                    Facebook account). The types of personal information collected may include your full
                    name, email address, gender, IP address, browser information, username, demographic
                    information, and any other information necessary for us to provide the Application
                    services.
                </div>
                <div class="subheading">Personal Information Collected Automatically:</div>
                <div>We receive and store certain types of usage related information whenever you interact with Application. For example, FLITM may
                    automatically receive and record information regarding your computer’s IP address, browser
                    information, Facebook user ID, Facebook Page fan status, and URLs accessed. Such
                    information may be shared in aggregate (non-personally identifiable) form with our
                    partners.
                </div>
                <div class="subheading">HOW DOES FLITM USE THE INFORMATION IT COLLECTS?</div>
                <div>FLITM uses the information described in this Privacy Statement (i) internally, to analyze, develop and improve its products and services,
                    and (ii) as set forth below in the “Will FLITM Share any of the personal information it Collects” section below.
                </div>
                <div class="subheading">APPLICATION PARTNER TREATMENT OF PERSONAL INFORMATION.</div>
                <div>FLITM may provide personal information to the applicable Application Partner. The Application Partner’s use of your personal information is
                    subject to the Application Partner’s separate privacy policy – and not this Privacy Statement. The Application Partner’s privacy policy is linked to from within the
                    Partner’s Facebook application.
                </div>
                <div class="subheading">WILL FLITM SHARE ANY OF THE PERSONAL INFORMATION IT
                    RECEIVES?
                </div>
                <div>Personal information about our users is an integral part of our business. We neither rent nor sell your personal information to anyone (with the
                    exception of sharing your information with an applicable Application Partner – see the “Application Partner Treatment” section above). We share your personal information
                    only as described below.
                </div>
                <br/>
                <div>Application Partners: We will share your personal information with an applicable Application Partner (see the “Application Partner Treatment” section above).</div>
                <br/>
                <div>Agents: We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or
                    services to you. Unless we tell you differently, FLITM agents do not have any right to use personal information we share with them beyond what is necessary to assist us.
                    You hereby consent to our sharing of personal information for the above purposes.

                    Business Transfers: In some cases, we may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that are
                    transferred. Moreover, if FLITM, or substantially all of its assets were acquired, or in the unlikely event that FLITM goes out of business or
                    enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any
                    acquirer of FLITM may continue to use your personal information as set forth in this policy.
                </div>
                <br/>
                <div>Protection of FLITM and Others: We may release personal information when we believe in good faith that release is necessary to comply with
                    the law; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of FLITM , our employees, our users, or others. This
                    includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
                </div>
                <br/>
                <div>With Your Consent: Except as set forth above, you will be
                    notified
                    when your personal information may be shared with third parties, and will be able to
                    prevent the sharing of this information.
                </div>
                <div class="subheading">CONDITIONS OF USE.</div>
                <div>If you decide to use or otherwise access the Application, your use/access and any possible dispute over privacy is subject to this Privacy
                    Statement and our Terms of Use, including limitations on damages, arbitration of disputes, and application of California state law.
                </div>
                <div class="subheading">THIRD PARTY APPLICATIONS/WEBSITES.</div>
                <div>The Application may permit you to link to other applications or websites. Such third party applications/websites are not under FLITM's
                    control, and such links do not constitute an endorsement by FLITM of those other
                    applications/websites or the services offered through them. The privacy and security
                    practices of such third party application/websites linked to the Application are not
                    covered by this Privacy Statement, and FLITM is not responsible for the
                    privacy or security practices or the content of such websites.
                </div>
                <div class="subheading">WHAT PERSONAL INFORMATION CAN I ACCESS?</div>
                <div>FLITM allows you to access the following information about you for the purpose of viewing, and in certain situations, updating that
                    information. This list may change in the event the Application changes.
                </div>
                <div>
                    - Account and user profile information<br/>
                    - User e-mail address, if applicable<br/>
                    - Facebook profile information, if applicable<br/>
                    - User preferences<br/>
                    - Application specific data<br/>
                    <div class="subheading">CAN CHILDREN USE THE APPLICATION?</div>
                    <div>Our site and the services available through FLITM are not intended for children under the age of 13. FLITM does not knowingly
                        or specifically collect information about children under the age of 13 and believes that children of any age should get their parents’ consent before giving out any
                        personal information. We encourage you to participate in your child’s web experience.
                    </div>
                    <div class="subheading">CHANGES TO THIS PRIVACY STATEMENT.</div>
                    <div>FLITM may amend this Privacy Statement from time to time. Use of information we collect now is subject to the Privacy Statement in effect at the time such information is used. If we make changes in the way we use personal information, we will notify you by posting an announcement on our Site or sending you an email. Users are bound by any changes to the Privacy Statement when he or she uses or otherwise accesses the Application after such changes have been first posted.
                        <br/><br/>
                    </div>
                    <div class="h5">
                        Effective Date: October 1, 2021
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  components: {}
}
</script>

<style lang="css" scoped>
.subheading {
  font-weight: bold;
  font-size: 1.2em;
  margin: 20px 0 20px 0;
}
.content {
  margin: 20px 0 20px 0;
  line-height: 2;
  text-align: justify;
  /* box-shadow: 0px 0px 16px #efefef; */
}
</style>
